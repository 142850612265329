@import "../../assets/variables.scss";

.story {
  z-index: 10;
  left: unset !important;
  top: unset !important;
  bottom: 2em !important;
  text-align: left;
  color: var(--accent-text);
  font-size: 12px;
  padding: 2em 10em;
  margin: auto;
  letter-spacing: 1px;
  font-style: italic;

  a {
    color: white;
  }

  .code {
    border-radius: 5px;
    padding: 0.75rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;
    font-family: IBM Plex Mono, Menlo, Monaco, Lucida Console, Liberation Mono,
      DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  }

  @media screen and (max-width: $break) {
    background: var(--sceneColor);
    justify-content: center;
    width: 100%;
    order: -1;
    position: absolute;
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    padding: 2em;
    margin: auto;
  }
}
