@import "./assets/variables";

@font-face {
  font-family: "legends";
  src: url("/fonts/legends.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
*:focus,
*:active {
  outline: none;
  box-shadow: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-main;
}
#screen {
  position: relative !important;
  transform: none !important;
  &:before {
    display: none !important;
  }
}

#portal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

//simulate hover on a canvas with a body class toggle
body.intersecting-key.editing #canvas-wrapper {
  cursor: url("/paintbrush.png") 0 47, auto !important;
}
.grabable {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
