@import "../../assets/variables.scss";

.actionBar {
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: 2em !important;
  top: 1em !important;
  text-align: left;
  color: var(--accent-text);
  letter-spacing: 2px;

  h1 {
    margin-bottom: 0px;
    letter-spacing: 10px;
    font-family: "Rubik", sans-serif;
  }

  a {
    color: var(--accent-text);
  }

  .code {
    border-radius: 5px;
    padding: 0.75rem;
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;
    font-family: IBM Plex Mono, Menlo, Monaco, Lucida Console, Liberation Mono,
      DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  }

  @media screen and (max-width: $break) {
    justify-content: center;
    width: 100%;
    order: -1;
    position: absolute;
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    padding: 2em;
    margin: auto;
  }
}

.discord {
  align-items: center;
  display: flex;
  z-index: 10;
  left: 6.9em !important;
  top: 6.9em !important;

  @media screen and (max-width: $break) {
    background: var(--sceneColor);
    justify-content: center;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    order: -1;
  }
}
